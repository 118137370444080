import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ImdLandingPage from './pages/imd/landing-page/landingpage';
import ImdSignupPage from './pages/imd/signup-page/signup-page';
import ImdSigninPage from './pages/imd/signin-page/signin-page';
import ImdCartPage from './pages/imd/cart-page/cart-page';

import WfLandingPage from './pages/wildflowers/landing-page/landingpage';
import WfSigninPage from './pages/wildflowers/signin-page/signin-page';
import WfCartPage from './pages/wildflowers/cart-page/cart-page';

import MsLandingPage from './pages/miindset/landing-page/landingpage';
import MsSignupPage from './pages/miindset/signup-page/signup-page';
import MsSigninPage from './pages/miindset/signin-page/signin-page';
import MsCartPage from './pages/miindset/cart-page/cart-page';

import GtLandingPage from './pages/genuine-tea/landing-page/landingpage';
import GtSignupPage from './pages/genuine-tea/signup-page/signup-page';
import GtSigninPage from './pages/genuine-tea/signin-page/signin-page';
import GtCartPage from './pages/genuine-tea/cart-page/cart-page';

import CULandingPage from './pages/cheoubc/landing-page/landingpage';
import CUSignupPage from './pages/cheoubc/signup-page/signup-page';
import CUSigninPage from './pages/cheoubc/signin-page/signin-page';
import CUCartPage from './pages/cheoubc/cart-page/cart-page';

import SYNLandingPage from './pages/synaptitude/landing-page/landingpage';
import SYNSignupPage from './pages/synaptitude/signup-page/signup-page';
import SYNSigninPage from './pages/synaptitude/signin-page/signin-page';
import SYNCartPage from './pages/synaptitude/cart-page/cart-page';

import IBMLandingPage from './pages/ibm/landing-page/landingpage';
import IBMSignupPage from './pages/ibm/signup-page/signup-page';
import IBMSigninPage from './pages/ibm/signin-page/signin-page';
import IBMCartPage from './pages/ibm/cart-page/cart-page';

import CAMHLandingPage from './pages/camh/landing-page/landingpage';
import CAMHSignupPage from './pages/camh/signup-page/signup-page';
import CAMHSigninPage from './pages/camh/signin-page/signin-page';
import CAMHCartPage from './pages/camh/cart-page/cart-page';

import PCCTLandingPage from './pages/pcct/landing-page/landingpage';
import PCCTSignupPage from './pages/pcct/signup-page/signup-page';
import PCCTSigninPage from './pages/pcct/signin-page/signin-page';
import PCCTCartPage from './pages/pcct/cart-page/cart-page';

import KLandingPage from './pages/kinduct/landing-page/landingpage';
import KSignupPage from './pages/kinduct/signup-page/signup-page';
import KSigninPage from './pages/kinduct/signin-page/signin-page';
import KCartPage from './pages/kinduct/cart-page/cart-page';

import ASLandingPage from './pages/appscript/landing-page/landingpage';
import ASSignupPage from './pages/appscript/signup-page/signup-page';
import ASSigninPage from './pages/appscript/signin-page/signin-page';
import ASCartPage from './pages/appscript/cart-page/cart-page';
import ASCheckoutPage from './pages/appscript/checkout-page/checkout-page';
import ASSuccessPage from './pages/appscript/success-page/success-page';

import MGLandingPage from './pages/math-guru/landing-page/landingpage';
import MGSignupPage from './pages/math-guru/signup-page/signup-page';
import MGSigninPage from './pages/math-guru/signin-page/signin-page';
import MGCartPage from './pages/math-guru/cart-page/cart-page';
import MGCheckoutPage from './pages/math-guru/checkout-page/checkout-page';
import MGSuccessPage from './pages/math-guru/success-page/success-page';

import PCHLandingPage from './pages/pchealth/landing-page/landingpage';
import PCHSignupPage from './pages/pchealth/signup-page/signup-page';
import PCHSigninPage from './pages/pchealth/signin-page/signin-page';
import PCHCartPage from './pages/pchealth/cart-page/cart-page';
import PCHCheckoutPage from './pages/pchealth/checkout-page/checkout-page';
import PCHSuccessPage from './pages/pchealth/success-page/success-page';

import SHLandingPage from './pages/synaptichacks/landing-page/landingpage';
import SHSignupPage from './pages/synaptichacks/signup-page/signup-page';
import SHSigninPage from './pages/synaptichacks/signin-page/signin-page';
import SHCartPage from './pages/synaptichacks/cart-page/cart-page';
import SHCheckoutPage from './pages/synaptichacks/checkout-page/checkout-page';
import SHSuccessPage from './pages/synaptichacks/success-page/success-page';

import AMLandingPage from './pages/amdtx/landing-page/landingpage';
import AMSignupPage from './pages/amdtx/signup-page/signup-page';
import AMSigninPage from './pages/amdtx/signin-page/signin-page';
import AMCartPage from './pages/amdtx/cart-page/cart-page';
import AMCheckoutPage from './pages/amdtx/checkout-page/checkout-page';
import AMSuccessPage from './pages/amdtx/success-page/success-page';

import MJLandingPage from './pages/mindfuljunkie/landing-page/landingpage';
import MJSignupPage from './pages/mindfuljunkie/signup-page/signup-page';
import MJSigninPage from './pages/mindfuljunkie/signin-page/signin-page';
import MJCartPage from './pages/mindfuljunkie/cart-page/cart-page';
import MJCheckoutPage from './pages/mindfuljunkie/checkout-page/checkout-page';
import MJSuccessPage from './pages/mindfuljunkie/success-page/success-page';

import MBLandingPage from './pages/manonbreton/landing-page/landingpage';
import MBSignupPage from './pages/manonbreton/signup-page/signup-page';
import MBSigninPage from './pages/manonbreton/signin-page/signin-page';
import MBCartPage from './pages/manonbreton/cart-page/cart-page';
import MBCheckoutPage from './pages/manonbreton/checkout-page/checkout-page';
import MBSuccessPage from './pages/manonbreton/success-page/success-page';

import JLLandingPage from './pages/julienlacaille/landing-page/landingpage';
import JLSignupPage from './pages/julienlacaille/signup-page/signup-page';
import JLSigninPage from './pages/julienlacaille/signin-page/signin-page';
import JLCartPage from './pages/julienlacaille/cart-page/cart-page';

import LDLandingPage from './pages/lifedrop/landing-page/landingpage';
import LDSignupPage from './pages/lifedrop/signup-page/signup-page';
import LDSigninPage from './pages/lifedrop/signin-page/signin-page';
import LDCartPage from './pages/lifedrop/cart-page/cart-page';

import GSLandingPage from './pages/gspace/landing-page/landingpage';
import GSSignupPage from './pages/gspace/signup-page/signup-page';
import GSSigninPage from './pages/gspace/signin-page/signin-page';
import GSCartPage from './pages/gspace/cart-page/cart-page';
import GSCheckoutPage from './pages/gspace/checkout-page/checkout-page';
import GSSuccessPage from './pages/gspace/success-page/success-page';

import LLandingPage from './pages/lumino/landing-page/landingpage';
import LSignupPage from './pages/lumino/signup-page/signup-page';
import LSigninPage from './pages/lumino/signin-page/signin-page';
import LCartPage from './pages/lumino/cart-page/cart-page';
import LCheckoutPage from './pages/lumino/checkout-page/checkout-page';
import LSuccessPage from './pages/lumino/success-page/success-page';

import SLLandingPage from './pages/sunlife/landing-page/landingpage';
import SLSignupPage from './pages/sunlife/signup-page/signup-page';
import SLSigninPage from './pages/sunlife/signin-page/signin-page';
import SLCartPage from './pages/sunlife/cart-page/cart-page';
import SLCheckoutPage from './pages/sunlife/checkout-page/checkout-page';
import SLSuccessPage from './pages/sunlife/success-page/success-page';

import GSCLandingPage from './pages/gsc/landing-page/landingpage';
import GSCSignupPage from './pages/gsc/signup-page/signup-page';
import GSCSigninPage from './pages/gsc/signin-page/signin-page';
import GSCCartPage from './pages/gsc/cart-page/cart-page';
import GSCCheckoutPage from './pages/gsc/checkout-page/checkout-page';
import GSCSuccessPage from './pages/gsc/success-page/success-page';

import CVMPCLandingPage from './pages/cvmpc/landing-page/landingpage';
import CVMPCSignupPage from './pages/cvmpc/signup-page/signup-page';
import CVMPCSigninPage from './pages/cvmpc/signin-page/signin-page';
import CVMPCCartPage from './pages/cvmpc/cart-page/cart-page';

import UoftLandingPage from './pages/uoft/landing-page/landingpage';
import UoftSignupPage from './pages/uoft/signup-page/signup-page';
import UoftSigninPage from './pages/uoft/signin-page/signin-page';
import UoftCartPage from './pages/uoft/cart-page/cart-page';
import UoftCheckoutPage from './pages/uoft/checkout-page/checkout-page';
import UoftSuccessPage from './pages/uoft/success-page/success-page';

import RhythmedicLandingPage from './pages/rhythmedic/landing-page/landingpage';
import RhythmedicSignupPage from './pages/rhythmedic/signup-page/signup-page';
import RhythmedicSigninPage from './pages/rhythmedic/signin-page/signin-page';
import RhythmedicCartPage from './pages/rhythmedic/cart-page/cart-page';
import RhythmedicCheckoutPage from './pages/rhythmedic/checkout-page/checkout-page';
import RhythmedicSuccessPage from './pages/rhythmedic/success-page/success-page';

import WindsorLandingPage from './pages/windsor/landing-page/landingpage';
import WindsorSignupPage from './pages/windsor/signup-page/signup-page';
import WindsorSigninPage from './pages/windsor/signin-page/signin-page';
import WindsorCartPage from './pages/windsor/cart-page/cart-page';
import WindsorCheckoutPage from './pages/windsor/checkout-page/checkout-page';
import WindsorSuccessPage from './pages/windsor/success-page/success-page';

import SeamlessLandingPage from './pages/seamless/landing-page/landingpage';
import SeamlessSignupPage from './pages/seamless/signup-page/signup-page';
import SeamlessSigninPage from './pages/seamless/signin-page/signin-page';
import SeamlessCartPage from './pages/seamless/cart-page/cart-page';
import SeamlessCheckoutPage from './pages/seamless/checkout-page/checkout-page';
import SeamlessSuccessPage from './pages/seamless/success-page/success-page';

import MindfulnessWorksLandingPage from './pages/mindfulnessworks/landing-page/landingpage';
import MindfulnessWorksSignupPage from './pages/mindfulnessworks/signup-page/signup-page';
import MindfulnessWorksSigninPage from './pages/mindfulnessworks/signin-page/signin-page';
import MindfulnessWorksCartPage from './pages/mindfulnessworks/cart-page/cart-page';

import StartupCareLandingPage from './pages/startupcare/landing-page/landingpage';
import StartupCareSignupPage from './pages/startupcare/signup-page/signup-page';
import StartupCareSigninPage from './pages/startupcare/signin-page/signin-page';
import StartupCareCartPage from './pages/startupcare/cart-page/cart-page';
import StartupCareCheckoutPage from './pages/startupcare/checkout-page/checkout-page';
import StartupCareSuccessPage from './pages/startupcare/success-page/success-page';

import SdmLandingPage from './pages/sdm/landing-page/landingpage';
import SdmSignupPage from './pages/sdm/signup-page/signup-page';
import SdmSigninPage from './pages/sdm/signin-page/signin-page';
import SdmCartPage from './pages/sdm/cart-page/cart-page';
import SdmCheckoutPage from './pages/sdm/checkout-page/checkout-page';
import SdmSuccessPage from './pages/sdm/success-page/success-page';

import RstLandingPage from './pages/rst/landing-page/landingpage';
import RstSignupPage from './pages/rst/signup-page/signup-page';
import RstSigninPage from './pages/rst/signin-page/signin-page';
import RstCartPage from './pages/rst/cart-page/cart-page';
import RstCheckoutPage from './pages/rst/checkout-page/checkout-page';
import RstSuccessPage from './pages/rst/success-page/success-page';

import MindfulnessLandingPage from './pages/mindfulness/landing-page/landingpage';
import MindfulnessSignupPage from './pages/mindfulness/signup-page/signup-page';
import MindfulnessSigninPage from './pages/mindfulness/signin-page/signin-page';
import MindfulnessCartPage from './pages/mindfulness/cart-page/cart-page';
import MindfulnessCheckoutPage from './pages/mindfulness/checkout-page/checkout-page';
import MindfulnessSuccessPage from './pages/mindfulness/success-page/success-page';

import CarevoiceLandingPage from './pages/carevoice/landing-page/landingpage';
import CarevoiceSignupPage from './pages/carevoice/signup-page/signup-page';
import CarevoiceSigninPage from './pages/carevoice/signin-page/signin-page';
import CarevoiceCartPage from './pages/carevoice/cart-page/cart-page';

import MPCLandingPage from './pages/mpc/landing-page/landingpage';
import MPCSignupPage from './pages/mpc/signup-page/signup-page';
import MPCSigninPage from './pages/mpc/signin-page/signin-page';
import MPCCartPage from './pages/mpc/cart-page/cart-page';

import LeagueLandingPage from './pages/league/landing-page/landingpage';
import LeagueSignupPage from './pages/league/signup-page/signup-page';
import LeagueSigninPage from './pages/league/signin-page/signin-page';
import LeagueCartPage from './pages/league/cart-page/cart-page';
import LeagueCheckoutPage from './pages/league/checkout-page/checkout-page';
import LeagueSuccessPage from './pages/league/success-page/success-page';

import HealthaideLandingPage from './pages/healthaide/landing-page/landingpage';
import HealthaideSignupPage from './pages/healthaide/signup-page/signup-page';
import HealthaideSigninPage from './pages/healthaide/signin-page/signin-page';
import HealthaideCartPage from './pages/healthaide/cart-page/cart-page';
import HealthaideCheckoutPage from './pages/healthaide/checkout-page/checkout-page';
import HealthaideSuccessPage from './pages/healthaide/success-page/success-page';

import MyyogaLandingPage from './pages/myyoga/landing-page/landingpage';
import MyyogaSignupPage from './pages/myyoga/signup-page/signup-page';
import MyyogaSigninPage from './pages/myyoga/signin-page/signin-page';
import MyyogaCartPage from './pages/myyoga/cart-page/cart-page';
import MyyogaCheckoutPage from './pages/myyoga/checkout-page/checkout-page';
import MyyogaSuccessPage from './pages/myyoga/success-page/success-page';

import OpeninnovationLandingPage from './pages/openinnovation/landing-page/landingpage';
import OpeninnovationSignupPage from './pages/openinnovation/signup-page/signup-page';
import OpeninnovationSigninPage from './pages/openinnovation/signin-page/signin-page';
import OpeninnovationCartPage from './pages/openinnovation/cart-page/cart-page';
import OpeninnovationCheckoutPage from './pages/openinnovation/checkout-page/checkout-page';
import OpeninnovationSuccessPage from './pages/openinnovation/success-page/success-page';

import UrbanwellnessLandingPage from './pages/urbanwellness/landing-page/landingpage';
import UrbanwellnessSignupPage from './pages/urbanwellness/signup-page/signup-page';
import UrbanwellnessSigninPage from './pages/urbanwellness/signin-page/signin-page';
import UrbanwellnessCartPage from './pages/urbanwellness/cart-page/cart-page';
import UrbanwellnessCheckoutPage from './pages/urbanwellness/checkout-page/checkout-page';
import UrbanwellnessSuccessPage from './pages/urbanwellness/success-page/success-page';

import MindfulnessdubaiLandingPage from './pages/mindfulnessdubai/landing-page/landingpage';
import MindfulnessdubaiSignupPage from './pages/mindfulnessdubai/signup-page/signup-page';
import MindfulnessdubaiSigninPage from './pages/mindfulnessdubai/signin-page/signin-page';
import MindfulnessdubaiCartPage from './pages/mindfulnessdubai/cart-page/cart-page';
import MindfulnessdubaiCheckoutPage from './pages/mindfulnessdubai/checkout-page/checkout-page';
import MindfulnessdubaiSuccessPage from './pages/mindfulnessdubai/success-page/success-page';

import FriendsLandingPage from './pages/friends/landing-page/landingpage';
import FriendsSignupPage from './pages/friends/signup-page/signup-page';
import FriendsSigninPage from './pages/friends/signin-page/signin-page';
import FriendsCartPage from './pages/friends/cart-page/cart-page';
import FriendsCheckoutPage from './pages/friends/checkout-page/checkout-page';
import FriendsSuccessPage from './pages/friends/success-page/success-page';

import WeworkLandingPage from './pages/wework/landing-page/landingpage';
import WeworkSignupPage from './pages/wework/signup-page/signup-page';
import WeworkSigninPage from './pages/wework/signin-page/signin-page';
import WeworkCartPage from './pages/wework/cart-page/cart-page';
import WeworkCheckoutPage from './pages/wework/checkout-page/checkout-page';
import WeworkSuccessPage from './pages/wework/success-page/success-page';

import GanLandingPage from './pages/gan/landing-page/landingpage';
import GanSignupPage from './pages/gan/signup-page/signup-page';
import GanSigninPage from './pages/gan/signin-page/signin-page';
import GanCartPage from './pages/gan/cart-page/cart-page';
import GanCheckoutPage from './pages/gan/checkout-page/checkout-page';
import GanSuccessPage from './pages/gan/success-page/success-page';

import SosvLandingPage from './pages/sosv/landing-page/landingpage';
import SosvSignupPage from './pages/sosv/signup-page/signup-page';
import SosvSigninPage from './pages/sosv/signin-page/signin-page';
import SosvCartPage from './pages/sosv/cart-page/cart-page';
import SosvCheckoutPage from './pages/sosv/checkout-page/checkout-page';
import SosvSuccessPage from './pages/sosv/success-page/success-page';

import NewcampusLandingPage from './pages/newcampus/landing-page/landingpage';
import NewcampusSignupPage from './pages/newcampus/signup-page/signup-page';
import NewcampusSigninPage from './pages/newcampus/signin-page/signin-page';
import NewcampusCartPage from './pages/newcampus/cart-page/cart-page';
import NewcampusCheckoutPage from './pages/newcampus/checkout-page/checkout-page';
import NewcampusSuccessPage from './pages/newcampus/success-page/success-page';

import AchtsamengagiertLandingPage from './pages/achtsamengagiert/landing-page/landingpage';
import AchtsamengagiertSignupPage from './pages/achtsamengagiert/signup-page/signup-page';
import AchtsamengagiertSigninPage from './pages/achtsamengagiert/signin-page/signin-page';
import AchtsamengagiertCartPage from './pages/achtsamengagiert/cart-page/cart-page';
import AchtsamengagiertCheckoutPage from './pages/achtsamengagiert/checkout-page/checkout-page';
import AchtsamengagiertSuccessPage from './pages/achtsamengagiert/success-page/success-page';

import MccnLandingPage from './pages/mccn/landing-page/landingpage';
import MccnSignupPage from './pages/mccn/signup-page/signup-page';
import MccnSigninPage from './pages/mccn/signin-page/signin-page';
import MccnCartPage from './pages/mccn/cart-page/cart-page';
import MccnCheckoutPage from './pages/mccn/checkout-page/checkout-page';
import MccnSuccessPage from './pages/mccn/success-page/success-page';

import CvcarnivalLandingPage from './pages/cvcarnival/landing-page/landingpage';
import CvcarnivalSignupPage from './pages/cvcarnival/signup-page/signup-page';
import CvcarnivalSigninPage from './pages/cvcarnival/signin-page/signin-page';
import CvcarnivalCartPage from './pages/cvcarnival/cart-page/cart-page';
import CvcarnivalCheckoutPage from './pages/cvcarnival/checkout-page/checkout-page';
import CvcarnivalSuccessPage from './pages/cvcarnival/success-page/success-page';

import BuisconsultLandingPage from './pages/buisconsult/landing-page/landingpage';
import BuisconsultSignupPage from './pages/buisconsult/signup-page/signup-page';
import BuisconsultSigninPage from './pages/buisconsult/signin-page/signin-page';
import BuisconsultCartPage from './pages/buisconsult/cart-page/cart-page';
import BuisconsultCheckoutPage from './pages/buisconsult/checkout-page/checkout-page';
import BuisconsultSuccessPage from './pages/buisconsult/success-page/success-page';

import EclecticLandingPage from './pages/eclectic/landing-page/landingpage';
import EclecticSignupPage from './pages/eclectic/signup-page/signup-page';
import EclecticSigninPage from './pages/eclectic/signin-page/signin-page';
import EclecticCartPage from './pages/eclectic/cart-page/cart-page';
import EclecticCheckoutPage from './pages/eclectic/checkout-page/checkout-page';
import EclecticSuccessPage from './pages/eclectic/success-page/success-page';

import GlocoachLandingPage from './pages/glocoach/landing-page/landingpage';
import GlocoachSignupPage from './pages/glocoach/signup-page/signup-page';
import GlocoachSigninPage from './pages/glocoach/signin-page/signin-page';
import GlocoachCartPage from './pages/glocoach/cart-page/cart-page';
import GlocoachCheckoutPage from './pages/glocoach/checkout-page/checkout-page';
import GlocoachSuccessPage from './pages/glocoach/success-page/success-page';

import SNICLandingPage from './pages/snic/landing-page/landingpage';
import SNICSignupPage from './pages/snic/signup-page/signup-page';
import SNICSigninPage from './pages/snic/signin-page/signin-page';
import SNICCartPage from './pages/snic/cart-page/cart-page';
import SNICCheckoutPage from './pages/snic/checkout-page/checkout-page';
import SNICSuccessPage from './pages/snic/success-page/success-page';

import MbcsLandingPage from './pages/mbcs/landing-page/landingpage';
import MbcsSignupPage from './pages/mbcs/signup-page/signup-page';
import MbcsSigninPage from './pages/mbcs/signin-page/signin-page';
import MbcsCartPage from './pages/mbcs/cart-page/cart-page';
import MbcsCheckoutPage from './pages/mbcs/checkout-page/checkout-page';
import MbcsSuccessPage from './pages/mbcs/success-page/success-page';

import Cheot1dLandingPage from './pages/cheot1d/landing-page/landingpage';
import Cheot1dSignupPage from './pages/cheot1d/signup-page/signup-page';
import Cheot1dSigninPage from './pages/cheot1d/signin-page/signin-page';
import Cheot1dCartPage from './pages/cheot1d/cart-page/cart-page';

import MskccLandingPage from './pages/mskcc/landing-page/landingpage';
import MskccSignupPage from './pages/mskcc/signup-page/signup-page';
import MskccSigninPage from './pages/mskcc/signin-page/signin-page';
import MskccCartPage from './pages/mskcc/cart-page/cart-page';

import PtbiWestLandingPage from './pages/ptbiwest/landing-page/landingpage';
import PtbiWestSignupPage from './pages/ptbiwest/signup-page/signup-page';
import PtbiWestSigninPage from './pages/ptbiwest/signin-page/signin-page';
import PtbiWestCartPage from './pages/ptbiwest/cart-page/cart-page';

import ViplabLandingPage from './pages/viplab/landing-page/landingpage';
import ViplabSignupPage from './pages/viplab/signup-page/signup-page';
import ViplabSigninPage from './pages/viplab/signin-page/signin-page';
import ViplabCartPage from './pages/viplab/cart-page/cart-page';

import GoogleCloseBrowser from './pages/google-close-browser/GoogleCloseBrowser';
// import './App.css';

import ApiDataPage from './pages/apidata/apidata';
import ApiDataDetailPage from './pages/apidata/apidata-detail';

function App() {
  return (
    <Switch>
      {/* AmDTx */}
      <Route exact path="/">
        <AMLandingPage />
      </Route>
      <Route exact path="/AmBJS">
        <AMLandingPage />
      </Route>
      <Route exact path="/signup">
        <AMSignupPage />
      </Route>
      <Route exact path="/signin">
        <AMSigninPage />
      </Route>
      <Route exact path="/cart">
        <AMCartPage />
      </Route>
      <Route exact path="/checkout">
        <AMCheckoutPage />
      </Route>
      <Route exact path="/success">
        <AMSuccessPage />
      </Route>

      <Route exact path="/google/close">
        <GoogleCloseBrowser />
      </Route>
    </Switch>
  );
}

export default App;
