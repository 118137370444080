import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CustomButton from '../custom-button/custom-button';
import { getCompanyTheme } from '../../utils/company';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  card: {
    maxWidth: '353px',
    flexGrow: '1',
    flexBasis: '0',
    marginRight: '47px',
    marginBottom: '30px',
    width: '100%',
    // height: '239px',
    // marginRight: '44px',
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: '#F9B257',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '29px',
    color: ' #FFFFFF',
  },
  cardPricing: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function PricingCard({
  title,
  color,
  price,
  priceId,
  saving,
  discount,
  currency,
  userDetails,
  email,
  serverUrl,
}) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;
  const { t } = useTranslation();

  let duration = '';
  if (title === 'YEARLY' || title === t('yearly')) {
    duration = t('year');
  } else if (title === 'LIFETIME' || title === t('lifetime')) {
    duration = t('one_time');
  } else {
    duration = t('month');
  }

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      {/* <Container component="main"> */}
      {/* <Grid container spacing={5} alignItems="flex-end">
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          > */}
      <Card className={classes.card}>
        {/* <CardActionArea> */}
        <CardHeader
          title={title}
          titleTypographyProps={{ align: 'left' }}
          //   action={tier.title === 'Pro' ? <StarIcon /> : null}
          className={classes.cardHeader}
          style={{ backgroundColor: color, paddingLeft: '34px' }}
        />
        <CardContent style={{ paddingLeft: '34px' }}>
          <div className={classes.cardPricing}>
            <Typography
              component="h3"
              variant="h4"
              color="textPrimary"
              style={{
                borderLeft: `4px solid ${color}`,
                paddingLeft: '10px',
              }}
            >
              {currency + price}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              /{duration}
            </Typography>
          </div>
          {company === 'gsc' || company === 'lumino' ? null : (
            <>
              <div>
                {t('discount')}: {discount}
              </div>
              <div>
                {t('savings')}: {currency + saving}
              </div>
            </>
          )}
        </CardContent>
        <CardActions
          style={{ justifyContent: 'center', marginBottom: '15px' }}
        >
          <CustomButton
            onClick={() =>
              history.push(`/checkout`, {
                userDetails: userDetails,
                company: company,
                color: getCompanyTheme(company),
                email: email,
                priceId: priceId,
                serverUrl: serverUrl,
                planDuration: title,
              })
            }
            backgroundColor={getCompanyTheme(company)}
          >
            {t('select')}
          </CustomButton>
        </CardActions>
        {/* </CardActionArea> */}
      </Card>
      {/* </Grid>
        </Grid> */}
      {/* </Container> */}
    </React.Fragment>
  );
}
