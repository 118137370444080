import React from 'react';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../custom-button/custom-button';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: '40px 0',
  },
  mobButtonContainer: {
    margin: '40px 0',
  },
}));

export default function ActivateButton({
  backgroundColor,
  sm,
  label,
  style,
  onClick,
}) {
  const classes = useStyles({ sm });

  return (
    <div
      className={
        sm ? classes.mobButtonContainer : classes.buttonContainer
      }
      style={style}
    >
      <CustomButton
        onClick={onClick}
        backgroundColor={backgroundColor}
      >
        {label}
      </CustomButton>
    </div>
  );
}
