import { BASE_CDN_URL } from './constants';

//returns coupon code
export const getFreeCoupon = (company, optionalCoupon) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return 'imd-6wks';
    case 'wildflowers':
      return 'wildflowers-3mth';
    case 'miindset':
      return 'miindset-fmth';
    case 'genuinetea':
      return 'genuinetea-3mth';
    case 'ibm':
      return 'ibm-hyr';
    case 'pcct':
      return 'pcct-lf';
    case 'kinduct':
      return 'kinduct-fmth';
    case 'mathguru':
      return 'mathguru-fmth';
    case 'cheoubc':
      return 'cheoubc';
    case 'cvmpc':
      return 'cvmpc-fyr';
    case 'mpc':
      return 'cvmpc-fyr';
    case 'gsc':
      return 'gsc-hyr';
    case 'synaptitude':
      return 'synaptitude-3mth';
    case 'camh':
      return 'camh2';
    case 'julienlacaille':
      return 'julienlacaille-fmth3';
    case 'lifedrop':
      return 'lifedrop-2mth';
    case 'carevoice':
      return 'carevoice';
    case 'eclectic':
      return 'eclectic-2mth';
    case 'glocoach':
      return 'glocoach-2mth';
    case 'rhythmedic':
      return 'rhythmedic-1mth';
    case 'windsor':
      return 'windsor-fyr';
    case 'seamless':
      return 'seamless-2yr';
    case 'mindfulnessworks':
      return 'seamless-2yr';
    case 'mbcs_ca':
      return 'mbcs_ca-2yr';
    case 'cheot1d':
      return 'cheot1d-2yr';
    case 'mskcc':
      return 'mskcc-2yr';
    case 'ptbiwest':
      return 'ubctbi';
    case 'sosv':
      return 'sosv-1mth2';
    case 'uw':
      return 'uw-fyr';
    default:
      return company;
  }
};

// returns if a company is elgible for free coupon
export const isFreeCoupon = (company) => {
  company = company.toLowerCase();
  switch (company) {
    case 'imd':
      return true;
    case 'wildflowers':
      return true;
    case 'miindset':
      return true;
    case 'genuinetea':
      return true;
    case 'ibm':
      return true;
    case 'julienlacaille':
      return true;
    case 'pcct':
      return true;
    case 'kinduct':
      return true;
    case 'mathguru':
      return true;
    case 'cheoubc':
      return true;
    case 'cvmpc':
      return true;
    case 'mpc':
      return true;
    case 'gsc':
      return true;
    case 'synaptitude':
      return true;
    case 'camh':
      return true;
    case 'windsor':
      return true;
    case 'lifedrop':
      return true;
    case 'carevoice':
      return true;
    case 'eclectic':
      return true;
    case 'glocoach':
      return true;
    case 'rhythmedic':
      return true;
    case 'seamless':
      return true;
    case 'mbcs_ca':
      return true;
    case 'cheot1d':
      return true;
    case 'mskcc':
      return true;
    case 'ptbiwest':
      return true;
    case 'sosv':
      return true;
    case 'uw':
      return true;
    default:
      return false;
  }
};

// returns if a company is elgible for claim coupon
export const isClaimCoupon = (company) => {
  company = company.toLowerCase();
  switch (company) {
    default:
      return false;
  }
};

// returns company theme
export const getCompanyTheme = (company) => {
  return '#F9B257';
};

export const getCompanySloganTheme = (company) => {
  return null;
};

// returns company name
export const getCompanyName = (company) => {
  return 'AmDTx';
};

// returns company logo
export const getLogo = (company) => {
  return BASE_CDN_URL + '/am-icon.png';
};

// returns company background image (right container)
export const getBackgroundImg = (company) => {
  return BASE_CDN_URL + '/fish-bg.jpg';
};

export const returnErrorMessage = (serverMessage) => {
  switch (serverMessage) {
    case 'ServerAccountAlreadyRegistered':
      return 'ServerAccountAlreadyRegistered';
    case 'ServerInvalidPassword':
      return 'ServerInvalidPassword';
    case 'ServerInvalidEmail':
      return 'ServerInvalidEmail';
    case 'ServerNoMatchingAccount':
      return 'ServerNoMatchingAccount';
    case 'ServerPasswordIncorrect':
      return 'ServerPasswordIncorrect';
    case 'GSCNotVerified':
      return 'GSCNotVerified';
    case 'DependentInvalid':
      return 'DependentInvalid';
    default:
      return serverMessage;
  }
};

export const getDuration = (company) => {
  company = company.toLowerCase();
  switch (getFreeCoupon(company)) {
    case 'imd-6wks':
      return '4 months';
    case 'wildflowers-3mth':
    case 'genuinetea-3mth':
    case 'synaptitude-3mth':
      return '3 months';
    case 'miindset-fmth':
    case 'kinduct-fmth':
    case 'mathguru-fmth':
      return '1 month';
    case 'ibm-hyr':
    case 'gsc-hyr':
      return '6 months';
    case 'cheoubc':
    case 'ubctbi': 
    case 'cvmpc-fyr':
      return '1 year';
    case 'camh2':
      return '1 year';
    case 'rhythmedic-1mth':
      return '1 month';
    case 'windsor':
      return '1 year';
    case 'seamless-2yr':
      return '2 years';
    case 'julienlacaille-fmth3':
      return '1';
    case 'lifedrop-2mth':
      return '2';
    case 'carevoice':
      return '1 year';
    case 'eclectic':
      return '2 months';
    case 'glocoach':
      return '2 months';
    case 'snic':
      return '2 weeks';
    case 'mbcs_ca-2yr':
      return '2 years';
    case 'cheot1d-2yr':
      return '2 years';
    case 'mskcc-2yr':
      return '2 years';
    case 'sosv-1mth2':
      return '1 month';
    case 'uw-fyr':
      return '1 year';
    default:
      return '2 weeks';
  }
};

export const pad = (value) => {
  return value < 10 ? '0' + value : value;
};

export const getTimezone = () => {
  var timezone = new Date().getTimezoneOffset();
  var sign = (timezone > 0) ? "-" : "+";
  var offset = Math.abs(timezone);
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ":" + minutes;
}
