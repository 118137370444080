import React from 'react';
import { sendAmplitudeData } from '../../utils/amplitude';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  link2Mob: {
    width: '156px',
    height: '60px',
    position: 'relative',
    left: '-10px',
    marginBottom: '40px'
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '41px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginLeft: '41px',
    flexDirection: 'column',
  },
}));

export default function SocialIcons({
  company,
  platform,
  sm,
  style,
}) {
  const classes = useStyles({ sm });

  const handleIosButton = () => {
    sendAmplitudeData('IOS_button_clicked', {
      company: company,
      device_platform: platform,
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  const handleAndroidButton = () => {
    sendAmplitudeData('Android_button_clicked', {
      company: company,
      device_platform: platform,
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  return (
    <div
      className={sm ? classes.linkWrapperMob : classes.linkWrapper}
      style={style}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://apps.apple.com/ca/app/i-am-mindful/id1300628961?utm_campaign=MI%20Website%20&utm_medium=link&utm_source=MI%20Website"
        onClick={handleIosButton}
      >
        <img
          src="https://d1w2eyfnx88jzn.cloudfront.net/v2/AppStore.png"
          alt="app-store-link"
          className={classes.link1}
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.mobiointeractive.wildflowers2&hl=en&utm_source=MI%20Website%20&utm_medium=link&utm_campaign=MI%20Website%20"
        onClick={handleAndroidButton}
      >
        <img
          src="https://d1w2eyfnx88jzn.cloudfront.net/v2/GooglePlay.png"
          alt="google-play-link"
          className={sm ? classes.link2Mob : classes.link2}
        />
      </a>
    </div>
  );
}
