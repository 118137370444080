import axios from 'axios';
import { returnErrors } from './errorActions';
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from './types';
import { history } from '../../utils/history';
import { Base64 } from 'js-base64';
import {
  getCompanyTheme,
  getFreeCoupon,
  isFreeCoupon,
  isClaimCoupon,
  getTimezone,
} from '../../utils/company';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../utils/amplitude';
import { BASE_API_URL } from '../../utils/constants';

export const BASE_CN_API_URL =
  'https://webapp-cn.mobiointeractive.cn';
// Check token & load user
// export const loadUser = () => (dispatch, getState) => {
//   // User loading
//   dispatch({ type: USER_LOADING });

//   axios
//     .get('/api/auth/user', tokenConfig(getState))
//     .then((res) =>
//       dispatch({
//         type: USER_LOADED,
//         payload: res.data,
//       }),
//     )
//     .catch((err) => {
//       dispatch(returnErrors(err.response.data, err.response.status));
//       dispatch({
//         type: AUTH_ERROR,
//       });
//     });
// };

//Get GSC Plan Details
export const getGscPlanDetails = (
  planDetails,
  userDetails,
  company,
  source,
) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
    },
  };

  const { planMemberId, participantBirthDate } = planDetails;
  // const {
  //   email,
  //   password,
  //   role,
  //   config_name,
  //   clientVersion,
  // } = userDetails;
  // Request body
  const body = JSON.stringify({
    planMemberId,
    participantBirthDate,
  });
  dispatch({
    type: USER_LOADING,
  });
  axios
    .post(BASE_API_URL + '/gsc_plan_details', body, config)
    .then((res) => {
      // console.log(res);
      if (res.data.participantStatus !== 'Active') {
        // sendAmplitudeData('signup_error', {
        //   company: company,
        //   device_platform: detectPlatform(),
        //   time: new Date().toISOString(),
        //   origin: window.location.origin,
        //   message: res.data.message,
        // });
        dispatch(
          returnErrors(
            res.data.participantStatus,
            2,
            'REGISTER_FAIL',
          ),
        );
        dispatch({
          type: REGISTER_FAIL,
        });
      } else {
        // if (data) {
        //   linkAccount(data, res.data);
        // } else {
        if (source === 'login') {
          // console.log(window.location.pathname);
          dispatch(
            login(
              userDetails,
              company,
              true,
              false,
              planMemberId,
              res.data,
            ),
          );
        } else {
          dispatch(
            register(
              userDetails,
              company,
              true,
              false,
              planMemberId,
              res.data,
            ),
          );
        }
      }
    })
    .catch((err) => {
      // sendAmplitudeData('signup_error', {
      //   company: company,
      //   device_platform: detectPlatform(),
      //   time: new Date().toISOString(),
      //   origin: window.location.origin,
      //   message: err.response.data,
      // });
      dispatch(returnErrors(err.response.data, 2, 'REGISTER_FAIL'));
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

// Register User
export const register = (
  userDetails,
  company,
  sid,
  group,
  cvid,
  phase,
  gscResponse,
) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
      'offset': getTimezone()
    },
  };

  const {
    email,
    password,
    role,
    config_name,
    clientVersion,
    consent_subscribe,
  } = userDetails;

  const userGroup = localStorage.getItem('group') == 'null' ? null : localStorage.getItem('group');
  const id = cvid ? cvid : (localStorage.getItem('sid') == 'null' ? null : localStorage.getItem('sid'));
  const userPhase = phase ? phase : (localStorage.getItem('phase') == 'null' ? null : localStorage.getItem('phase'));
  const store = localStorage.getItem('store') == 'null' ? null : localStorage.getItem('store');

  const origin = localStorage.getItem('origin')
    ? localStorage.getItem('origin')
    : null;
  company = 'amdtx';
  const BASE_URL =
    company === 'gspace' ? BASE_CN_API_URL : BASE_API_URL;
  // console.log('register getting called', id);
  // Request body
  // const body = sid
  //   ? JSON.stringify({
  //       email,
  //       password,
  //       role,
  //       config_name,
  //       clientVersion,
  //       group: userGroup,
  //       sid: id,
  //       phase: userPhase,
  //       origin: origin,
  //       consent_subscribe,
  //     })
  //   : JSON.stringify({
  //       email,
  //       password,
  //       role,
  //       config_name,
  //       clientVersion,
  //       group: userGroup,
  //       consent_subscribe,
  //     });

  const body = JSON.stringify({
    company: company,
    email,
    password,
    role,
    config_name,
    clientVersion,
    group: userGroup,
    sid: id,
    store: store,
    phase: userPhase,
    origin: origin,
    consent_subscribe,
    country_code: getCountryCode(company)
  });
  dispatch({
    type: USER_LOADING,
  });
  axios
    .post(BASE_URL + '/account/create2', body, config)
    .then((res) => {
      // console.log(res);
      if (res.data.message === 'ServerAccountAlreadyRegistered') {
        sendAmplitudeData('signup_error', {
          company: company,
          device_platform: detectPlatform(),
          time: new Date().toISOString(),
          origin: window.location.origin,
          message: res.data.message,
        });
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            'REGISTER_FAIL',
          ),
        );
        dispatch({
          type: REGISTER_FAIL,
        });
      } else {
        sendAmplitudeData('signup_success', {
          company: company,
          device_platform: detectPlatform(),
          time: new Date().toISOString(),
          origin: window.location.origin,
          pathname: window.location.pathname,
        });
        dispatch(
          login(userDetails, company, sid, group, cvid, gscResponse),
        );
        // dispatch({
        //   type: REGISTER_SUCCESS,
        //   payload: res.data,
        // });
      }
    })
    .catch((err) => {
      sendAmplitudeData('signup_error', {
        company: company,
        device_platform: detectPlatform(),
        time: new Date().toISOString(),
        origin: window.location.origin,
        message: err.response.data,
      });
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          'REGISTER_FAIL',
        ),
      );
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

const getCountryCode = (company) => {
  switch (company) {
    case 'camh':
    case 'mbcs_ca':
    case 'cheot1d':
    case 'uw':
      return 'CA';
    case 'mskcc':
      return 'US';
  }

  return null
}

// Login User
export const login = (
  userDetails,
  company,
  sid,
  group,
  cvid,
  phase,
  gscResponse,
  // planDetails,
) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
    },
  };

  company = 'amdtx';
  const { email, password, clientVersion } = userDetails;
  const batch = getFreeCoupon(company);
  const color = getCompanyTheme(company);
  const userGroup = localStorage.getItem('group') == 'null' ? null : localStorage.getItem('group');
  const id =
    cvid && cvid !== null ? cvid : (localStorage.getItem('sid') == 'null' ? null : localStorage.getItem('sid'));
  const userPhase = phase ? phase : (localStorage.getItem('phase') == 'null' ? null : localStorage.getItem('phase'));
  const store = localStorage.getItem('store') == 'null' ? null : localStorage.getItem('store');

  const origin = localStorage.getItem('origin')
    ? localStorage.getItem('origin')
    : null;
  const BASE_URL =
    company === 'gspace' ? BASE_CN_API_URL : BASE_API_URL;
  // Request body
  // const body = sid
  //   ? JSON.stringify({
  //       user: email,
  //       password,
  //       clientVersion,
  //       group: userGroup,
  //       sid: id,
  //       origin: origin,
  //     })
  //   : JSON.stringify({
  //       user: email,
  //       password,
  //       clientVersion,
  //       group: userGroup,
  //     });

  const body = JSON.stringify({
    user: email,
    password,
    clientVersion,
    group: userGroup,
    sid: id,
    store: store,
    phase: userPhase,
    origin: origin
  })

  dispatch({
    type: USER_LOADING,
  });
  axios
    .post(BASE_URL + '/account/login', body, config)
    .then((res) => {
      console.log('res normal login', res)
      if (res.data.message === 'Success') {
        if (company === 'gsc' && res.data.client_id === null) {
          dispatch(
            returnErrors(
              'GSCNotValid',
              res.data.status,
              'LOGIN_FAIL',
            ),
          );
          dispatch({
            type: LOGIN_FAIL,
          });
        } else {
          sendAmplitudeData('login_success', {
            company: company,
            device_platform: detectPlatform(),
            time: new Date().toISOString(),
            origin: window.location.origin,
            pathname: window.location.pathname,
          });
          // if (isClaimCoupon(company)) {
          //   claimCoupon(res.data, company, batch, color);
          //   dispatch({
          //     type: LOGIN_SUCCESS,
          //     payload: res.data,
          //   });
          // }
          // if (planDetails && planDetails !== null) {
          //   getGscPlanDetails(
          //     planDetails,
          //     userDetails,
          //     company,
          //     res.data,
          //   );
          // }
          if (isFreeCoupon(company)) {
            if (company == "mpc") {
              mpcClaim(res.data, company, batch, color, sid, group);
            }
            else {
              freeTrial(res.data, company, batch, color, sid, group);
            }


            // only for gsc
            if (gscResponse && gscResponse !== null) {
              linkAccount(res.data, gscResponse);
            }
            dispatch({
              type: LOGIN_SUCCESS,
              payload: res.data,
            });
          } else {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: res.data,
            });
            history.push(`/cart`, {
              userDetails: res.data,
              batch: batch,
              company: company,
              color: color,
            });
          }
        }
      } else {
        sendAmplitudeData('login_error', {
          company: company,
          device_platform: detectPlatform(),
          time: new Date().toISOString(),
          origin: window.location.origin,
          message: res.data.message,
        });
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            'LOGIN_FAIL',
          ),
        );
        dispatch({
          type: LOGIN_FAIL,
        });
      }
    })
    .catch((err) => {
      sendAmplitudeData('login_error', {
        company: company,
        device_platform: detectPlatform(),
        time: new Date().toISOString(),
        origin: window.location.origin,
        message: err.response.data,
      });
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          'LOGIN_FAIL',
        ),
      );
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// // Claim Coupon
// export const claimCoupon = (userDetails, company, batch, color) => {
//   // console.log('free getting called', userDetails.server_url);
//   //headers
//   const config = {
//     headers: {
//       'Content-Type': 'application/json',
//       'Access-Control-Allow-Origin':
//         'https://' + window.location.host,
//       Authorization: `Bearer ${userDetails.token}`,
//     },
//   };

//   // Request body
//   const body = JSON.stringify({
//     local_time: new Date().toJSON(),
//     code: localStorage.getItem('group'),
//     user_id: userDetails.uuid,
//   });

//   axios
//     .post(userDetails.server_url + '/coupon/claim-user', body, config)
//     .then((res) => {
//       history.push(`/${company}/cart`, {
//         userDetails: userDetails,
//         batch: batch,
//         company: company,
//         color: color,
//       });
//     });
//   // .catch((err) => {
//   //   dispatch(
//   //     returnErrors(
//   //       err.response.data,
//   //       err.response.status,
//   //       'REGISTER_FAIL',
//   //     ),
//   //   );
//   //   dispatch({
//   //     type: REGISTER_FAIL,
//   //   });
//   // });
// };

// MPC Claim
export const mpcClaim = (
  userDetails,
  company,
  batch,
  color,
  sid,
  group,
) => {
  // console.log('free getting called', userDetails.server_url);
  //headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
      Authorization: `Bearer ${userDetails.token}`,
      'offset': getTimezone()
    },
  };

  // Request body
  const body = JSON.stringify({
    CODE: localStorage.getItem('mpc_coupon'),
    LOCAL_TIME: new Date(),
    MS: [
      localStorage.getItem('maia_1'),
      localStorage.getItem('maia_2')
    ],
    PS: "J18Productivity",
    TS: [
      localStorage.getItem('teams_1'),
      localStorage.getItem('teams_2')
    ]
  });

  console.log('body', body)
  console.log('uuid', userDetails.uuid)

  axios
    .post(
      userDetails.server_url + '/coupon/mpc/claim/' + userDetails.uuid,
      body,
      config,
    )
    .then((res) => {
      history.push(`/cart`, {
        userDetails: userDetails,
        batch: batch,
        company: company,
        color: color,
      });
    });
};

// Free trial
export const freeTrial = (
  userDetails,
  company,
  batch,
  color,
  sid,
  group,
) => {
  console.log('free getting called', userDetails.server_url);
  //headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
      Authorization: `Bearer ${userDetails.token}`,
      'offset': getTimezone(),
    },
  };
  const userGroup = group ? (localStorage.getItem('group') == 'null' ? null : localStorage.getItem('group')) : company;

  // Request body
  const body = sid
    ? JSON.stringify({
      batch: batch,
      company: company,
      isFree: true,
      store: userGroup,
      label: localStorage.getItem('sid'),
    })
    : JSON.stringify({
      batch: batch,
      company: company,
      isFree: true,
      store: userGroup,
    });

  const body2 = JSON.stringify({
    removePeriods: [],
    removeSnapshots: [],
    removeSessions: [],
    updatePeriods: [],
    updateSessions: [],
    updateSnapshots: [],
    updateSettings: {
      is_active_control_account: localStorage.getItem('group') === 'cheo1' ? true : false,
      phase: localStorage.getItem('phase'),
    },
    version: -1,
    removeSettings: [],
  });

  if (company === 'cheoubc' || company === 'ptbiwest') {
    // console.log('com', company);
    axios
      .post(userDetails.server_url + '/account/sync', body2, config)
      .then((res) => {
        console.log('sync', res);
        // dispatch({
        //   type: LOGIN_SUCCESS,
        //   payload: userDetails,
        // });
        //   history.push(`/${company}/cart`, {
        //     userDetails: userDetails,
        //     batch: batch,
        //     company: company,
        //     color: color,
        //   });
      });
  }

  axios
    .post(
      userDetails.server_url + '/ecommerce/create/free_trial',
      body,
      config,
    )
    .then((res) => {
      // console.log('freetrial', res);
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: userDetails,
      // });
      history.push(`/cart`, {
        userDetails: userDetails,
        batch: batch,
        company: company,
        color: color,
      });
    });
  // .catch((err) => {
  //   dispatch(
  //     returnErrors(
  //       err.response.data,
  //       err.response.status,
  //       'REGISTER_FAIL',
  //     ),
  //   );
  //   dispatch({
  //     type: REGISTER_FAIL,
  //   });
  // });
};

//GSC only
export const linkAccount = (userDetails, gscResponse) => {
  const body = JSON.stringify({
    data: JSON.stringify(gscResponse),
  });
  //headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
      Authorization: `Bearer ${userDetails.token}`,
    },
  };

  axios
    .post(userDetails.server_url + '/link_account', body, config)
    .then((res) => {
      console.log(res);

      // .catch((err) => {
      //   dispatch(
      //     returnErrors(
      //       err.response.data,
      //       err.response.status,
      //       'REGISTER_FAIL',
      //     ),
      //   );
      //   dispatch({
      //     type: REGISTER_FAIL,
      //   });
    });
};

// Logout User
export const logout = () => (dispatch) => {
  // return {
  //   type: LOGOUT_SUCCESS,
  // };
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  // history.push('/');
};

// Setup config/headers and token
// export const tokenConfig = (getState) => {
//   // Get token from localstorage
//   const token = getState().auth.token;

//   // Headers
//   const config = {
//     headers: {
//       'Content-type': 'application/json',
//     },
//   };

//   // If token, add to headers
//   if (token) {
//     config.headers['x-auth-token'] = token;
//   }

//   return config;
// };

// Google Signin
export const openGoogleLogin = (company) => {
  company = 'amdtx';
  let googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?';
  googleUrl =
    googleUrl +
    'client_id=1072282051942-9536gl82rdmabkcq0noc0dsaghf5405i.apps.googleusercontent.com&response_type=code';
  // 'client_id=1072282051942-9536gl82rdmabkcq0noc0dsaghf5405i.apps.googleusercontent.com&response_type=code'
  googleUrl =
    googleUrl +
    '&scope=openid email profile&redirect_uri=https://ammindfulness.com/google/close';
  googleUrl =
    googleUrl + '&include_granted_scopes=true&prompt=consent';
  // console.log(window.location);
  // let state =
  //   '{"clientVersion":"1.0.6","urlScheme":"am-default","config_name":"default","redirect_uri":"https://mi.ammindfulness.com/google/close"}';
  const state = JSON.stringify({
    clientVersion: '1.0.6',
    urlScheme: 'am-default',
    config_name: 'default',
    redirect_uri: window.location.origin + '/google/close',
    group: company,
  });
  let child = window.open(
    googleUrl + '&state=' + Base64.encode(state),
    'sharer',
    'toolbar=0,status=0,width=800,height=500, left=300, top=200',
  );
  const timeout = setInterval(() => {
    if (child !== null && child.closed) {
      if (localStorage.getItem('query')) {
        // console.log('q', localStorage.getItem('query'));
        // // this.props.load();
        signInGoogle(
          BASE_API_URL +
          '/google/confirm/estore?' +
          localStorage.getItem('query'),
          company,
        );
      }
      localStorage.removeItem('query');
      clearInterval(timeout);
    }
  }, 500);
};

const signInGoogle = (url, company) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':
        'https://' + window.location.host,
      'offset': getTimezone()
    },
  };

  const batch = getFreeCoupon(company);
  const color = getCompanyTheme(company);
  const group = localStorage.getItem('group') == 'null' ? null : localStorage.getItem('group');
  const sid = localStorage.getItem('sid') == 'null' ? null : localStorage.getItem('sid');

  axios
    .get(url, config)
    .then((res) => {
      console.log('rest google', res);
      if (res.data.message === 'Success') {
        sendAmplitudeData('google_signin', {
          company: company,
          device_platform: detectPlatform(),
          time: new Date().toISOString(),
          origin: window.location.origin,
          pathname: window.location.pathname,
        });
        // if (isClaimCoupon(company)) {
        //   claimCoupon(
        //     res.data,
        //     company,
        //     getFreeCoupon(company),
        //     getCompanyTheme(company),
        //   );
        //   localStorage.setItem('token', res.data.token);
        // }
        console.log('res.data', res.data);
        if (isFreeCoupon(company)) {
          if (company == "mpc") {
            mpcClaim(res.data, company, batch, color, sid, group);
          }
          else {
            freeTrial(
              res.data,
              company,
              getFreeCoupon(company),
              getCompanyTheme(company),
            );
          }

          localStorage.setItem('token', res.data.token);
        } else {
          localStorage.setItem('token', res.data.token);
          history.push(`/cart`, {
            userDetails: res.data,
            batch: getFreeCoupon(company),
            company: company,
            color: getCompanyTheme(company),
          });
        }
      } else {
        // console.log(res);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  // .catch((err) => {
  //   dispatch(
  //     returnErrors(
  //       err.response.data,
  //       err.response.status,
  //       'REGISTER_FAIL',
  //     ),
  //   );
  //   dispatch({
  //     type: REGISTER_FAIL,
  //   });
  // });
};
