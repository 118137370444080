import React, { useEffect } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CompanyLogo from '../../../components/company-logo/company-logo';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import CompanyDetailsFlex from '../../../components/company-details-flex/company-details-flex';
import CustomButton from '../../../components/custom-button/custom-button';
import {
  getCompanyTheme,
  getBackgroundImg,
  getLogo,
  getDuration,
} from '../../../utils/company';
import { BASE_CDN_URL } from '../../../utils/constants';
import {
  sendAmplitudeData,
  detectPlatform,
} from '../../../utils/amplitude';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 70px - 47px)',
    overflow: 'auto',
  },
  mainContainer: {
    height: '100%',
  },
  leftBackgroundImg: {
    backgroundImage: `url(${BASE_CDN_URL + '/grey-bg.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  rightBackgroundImg: {
    backgroundImage: `url(${getBackgroundImg('genuinetea')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftContainer: {
    marginLeft: '41px',
    marginRight: '56px',
    marginTop: '54px',
    marginBottom: '20px',
  },
  textContainer: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#484848',
    marginTop: '43px',
  },
  storeContainer: {
    height: 'calc(100vh - 119px)',
  },
  buttonContainer: {
    marginTop: '81px',
  },
  imgContainer: {
    height: '58px',
    width: '57px',
  },
  link1: {
    width: '135px',
    height: '41px',
  },
  link2: {
    width: '156px',
    height: '60px',
  },
  link2Mob: {
    width: '156px',
    height: '60px',
    position: 'relative',
    left: '-10px',
  },
  linkWrapper: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginLeft: '41px',
    marginBottom: '31px',
    marginTop: '150px',
  },
  linkWrapperMob: {
    display: 'flex',
    alignSelf: 'flex-start',
    // alignItems: 'center',
    marginLeft: '41px',
    flexDirection: 'column',
    marginTop: '50px',
    marginBottom: '50px',
  },
}));

function LandingPage() {
  const sm = useMediaQuery('(max-width: 960px)');
  const leftPaneSize = sm ? 12 : 5;
  const classes = useStyles({ sm });
  const match = useRouteMatch();
  const history = useHistory();
  const { company } = match.params;

  useEffect(() => {
    sendAmplitudeData('landing_page_viewed', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });

    localStorage.setItem('sid', null);
    localStorage.setItem('group', null);
    localStorage.setItem('phase', null);
    localStorage.setItem('store', null);
  });

  const handleIosButton = () => {
    sendAmplitudeData('IOS_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  const handleAndroidButton = () => {
    sendAmplitudeData('Android_button_clicked', {
      company: company,
      device_platform: detectPlatform(),
      time: new Date().toISOString(),
      origin: window.location.origin,
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>
        <Grid container className={classes.mainContainer}>
          <Grid
            item
            xs={leftPaneSize}
            className={classes.leftBackgroundImg}
          >
            <div className={classes.leftContainer}>
              <CompanyLogo company={company} />
              <CompanyDetailsFlex
                slogan="DRINK TEA WITH MINDFULNESS"
                companyName={company}
              >
                <p>
                  Genuine Tea and Mobio Interactive present <b>Am</b>{' '}
                  Mindfulness, a clinically effective way to begin
                  your personalised mindfulness journey.
                </p>
                <p>
                  Track your emotions and feel the change within you.
                  Indulge in a variety of step-by-step Journeys. Ease
                  into the you, You love.{' '}
                </p>
                <p>
                  To redeem three months of free Premium access to{' '}
                  <b>Am</b>
                </p>
                <ul>
                  <li>
                    {' '}
                    Click the button below and create a user account.
                  </li>
                  <li>
                    Then download Am from the App Store of Google
                    Play, login and away you go!
                  </li>
                </ul>
              </CompanyDetailsFlex>
              <div className={classes.buttonContainer}>
                <CustomButton
                  onClick={() => history.push(`/${company}/signup`)}
                  backgroundColor={getCompanyTheme(company)}
                >
                  GET {getDuration(company)} FREE
                </CustomButton>
              </div>
            </div>
            <div
              className={
                sm ? classes.linkWrapperMob : classes.linkWrapper
              }
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/ca/app/i-am-mindful/id1300628961?utm_campaign=MI%20Website%20&utm_medium=link&utm_source=MI%20Website"
                onClick={handleIosButton}
              >
                <img
                  src="https://d1w2eyfnx88jzn.cloudfront.net/v2/AppStore.png"
                  alt="app-store-link"
                  className={classes.link1}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.mobiointeractive.wildflowers2&hl=en&utm_source=MI%20Website%20&utm_medium=link&utm_campaign=MI%20Website%20"
                onClick={handleAndroidButton}
              >
                <img
                  src="https://d1w2eyfnx88jzn.cloudfront.net/v2/GooglePlay.png"
                  alt="google-play-link"
                  className={sm ? classes.link2Mob : classes.link2}
                />
              </a>
            </div>
          </Grid>
          {sm ? null : (
            <Grid
              item
              xs={7}
              className={classes.rightBackgroundImg}
            ></Grid>
          )}
        </Grid>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
