import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logout } from '../../redux/actions/authActions';
import { ReactComponent as Logo } from '../../assets/MI_Logo.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import Grid from '@material-ui/core/Grid';
import { getCompanyTheme } from '../../utils/company';
import LanguageDropdown from '../language-dropdown/language-dropdown';

const useStyles = makeStyles({
  appbar: {
    background: '#FFFFFF',
    height: '70px',
    justifyContent: 'center',
    position: 'relative',
    // flexGrow: 1,
  },
  logoContainer: {
    height: '40px',
    width: '140px',
    flex: '1',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#484848',
    cursor: 'pointer',
    display: 'flex',
  },
});

const Header = ({ logout, isAuthenticated, en, cn, fr, nl, de }) => {
  // console.log('auth', isAuthenticated);
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const color = getCompanyTheme(match.params.company);
  const { t } = useTranslation();

  const handleLogout = () => {
    logout();
    if (match.params.company === 'cheoubc') {
      history.push({
        pathname: `/${match.params.company}`,
        search: `?sid=${localStorage.getItem(
          'raw_sid',
        )}&group=${localStorage.getItem('group')}`,
      });
    } else if (match.params.company === 'synaptitude') {
      history.push({
        pathname: `/${match.params.company}`,
        search: `?sid=${localStorage.getItem(
          'sid',
        )}&email=${localStorage.getItem(
          'email',
        )}&origin=${localStorage.getItem('origin')}`,
      });
    } else {
      history.push(`/`);
    }
  };
  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        {/* <Link to={`/${match.params.company}`} style={{ flex: 1 }}> */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Logo
              className={classes.logoContainer}
              onClick={() => history.push(`/${match.params.company}`)}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              {en || cn || fr || nl || de ? (
                <LanguageDropdown
                  en={en}
                  fr={fr}
                  nl={nl}
                  de={de}
                  cn={cn}
                />
              ) : null}
              {localStorage.getItem('token') ? (
                <div className={classes.text} onClick={handleLogout}>
                  <div style={{ marginRight: '5px' }}>
                    {t('logout')}
                  </div>
                  <LogoutIcon style={{ fill: color }} />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
  //   </div>
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Header);
// export default Header;

// {
//   /* <AppBar position="static">
//   <Toolbar>
//     <IconButton
//       edge="start"
//       className={classes.menuButton}
//       color="inherit"
//       aria-label="menu"
//     >
//       <MenuIcon />
//     </IconButton>
//     <Typography variant="h6" className={classes.title}>
//       News
//     </Typography>
//     <Button color="inherit">Login</Button>
//   </Toolbar>
// </AppBar>; */
// }
